// extracted by mini-css-extract-plugin
export const main = "main-module--main--1FvNJ";
export const article = "main-module--article--2jFsv";
export const headerbg = "main-module--headerbg--16AU3";
export const svgbg = "main-module--svgbg--1Wmkr";
export const headerbox = "main-module--headerbox--SRAzG";
export const hicon = "main-module--hicon--1Ovri";
export const h0 = "main-module--h0--NTeF_";
export const desc = "main-module--desc--1EfeX";
export const box = "main-module--box--2G4QO";
export const tbox = "main-module--tbox--2ZzNy";
export const tboxl = "main-module--tboxl--3lvVj";
export const tboxr = "main-module--tboxr--2bXVz";
export const txt = "main-module--txt--1T3jE";
export const htxt = "main-module--htxt--2apbL";
export const hr = "main-module--hr--3ikE5";
export const art0 = "main-module--art0--1ec-a";
export const art1 = "main-module--art1--VlUQi";
export const art2 = "main-module--art2--14cYR";
export const art3 = "main-module--art3--1Kgh6";
export const quotesbox = "main-module--quotesbox--BOoNQ";
export const quotes = "main-module--quotes--3oHbv";
export const quotestxt = "main-module--quotestxt--XRAHL";
export const quoteshr = "main-module--quoteshr--3xLbI";
export const quotesname = "main-module--quotesname--1Gffq";
export const artbox = "main-module--artbox--z4fCK";
export const artname = "main-module--artname--1FgZS";
export const art4 = "main-module--art4--DXfg-";
export const legend = "main-module--legend--3PbDG";
export const axis = "main-module--axis--y6q9K";
export const source = "main-module--source--29_8s";
export const sources = "main-module--sources--2EMg7";