import React, { useEffect } from 'react'
import * as s from "./main.module.css"

// import useSize from '../utils/useSize';

import { gsap } from "gsap/dist/gsap";

import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

import Icon from '../assets/article.svg';
import Quotes from '../assets/quotes.svg';

import { withpath } from '../utils/index.js'

import Art0 from '../assets/art0.svg';
import Art1 from '../assets/art1.svg';
import Art2 from '../assets/art2.svg';
import Art3 from '../assets/art3.svg';

import { Waypoint } from 'react-waypoint';

const Main = (p) => {

	const data = p.data
	const waypercent = "20%"

	gsap.config({
		autoSleep: 60,
		force3D: false,
		nullTargetWarn: false
	});

	useEffect(() => {

		let art0rect = document.querySelectorAll(`#art0rect`);
		let art0circle = document.querySelectorAll(`#art0circle`);
		let art0txt0 = document.querySelectorAll(`#art0txt0`);
		let art0txt1 = document.querySelectorAll(`#art0txt1`);
		gsap.killTweensOf([art0rect,art0circle,art0txt0,art0txt1]);
		gsap.set(art0rect, {opacity: 0, scale: 0.5, transformOrigin: "50% 50%"})
		gsap.set(art0circle, {opacity: 0, scale: 0.5, transformOrigin: "50% 50%"})
		gsap.set(art0txt0, {opacity: 0, y: 5})
		gsap.set(art0txt1, {opacity: 0, y: -5})

		let art1sun = document.querySelectorAll(`#art1sun`);
		let art1floor = document.querySelectorAll(`#art1floor`);
		let art1factory = document.querySelectorAll(`#art1factory`);
		let art1pipe1 = document.querySelectorAll(`#art1pipe1`);
		let art1pipe2 = document.querySelectorAll(`#art1pipe2`);
		gsap.killTweensOf([art1sun,art1floor,art1factory,art1pipe1,art1pipe2]);
		gsap.set(art1sun, {opacity: 0, y:40, x:10})
		gsap.set(art1floor, {opacity: 0})
		gsap.set(art1factory, {opacity: 0})
		gsap.set(art1pipe1, {opacity: 0, y: 20})
		gsap.set(art1pipe2, {opacity: 0, y: 20})

		let art2bar = document.querySelectorAll(`.art2bar`);
		let art2txt0 = document.querySelectorAll(`.art2txt0`);
		let art2txt1 = document.querySelectorAll(`.art2txt1`);
		gsap.killTweensOf([art2bar,art2txt0,art2txt1]);
		gsap.set(art2bar, {opacity: 0, scaleX: 0})
		gsap.set(art2txt0, {opacity: 0})
		gsap.set(art2txt1, {opacity: 0})

		let art3sun = document.querySelectorAll(`#art3sun`);
		let art3hospital = document.querySelectorAll(`#art3hospital`);
		let art3tower = document.querySelectorAll(`#art3tower`);
		let art3floor = document.querySelectorAll(`#art3floor`);
		let art3man = document.querySelectorAll(`#art3man`);
		let art3old = document.querySelectorAll(`#art3old`);
		gsap.killTweensOf([art3sun,art3hospital,art3tower,art3floor,art3man,art3old]);
		gsap.set(art3sun, {opacity: 0, y:40, x:10})
		gsap.set(art3hospital, {opacity: 0})
		gsap.set(art3tower, {opacity: 0})
		gsap.set(art3floor, {opacity: 0})
		gsap.set(art3man, {opacity: 0})
		gsap.set(art3old, {opacity: 0})

	},[])


	function enter0(){
		let art0rect = document.querySelectorAll(`#art0rect`);
		let art0circle = document.querySelectorAll(`#art0circle`);
		let art0txt0 = document.querySelectorAll(`#art0txt0`);
		let art0txt1 = document.querySelectorAll(`#art0txt1`);

		gsap.killTweensOf([art0rect,art0circle,art0txt0,art0txt1]);
		gsap.set(art0rect, {opacity: 0, scale: 0.5})
		gsap.set(art0circle, {opacity: 0, scale: 0.5})
		gsap.set(art0txt0, {opacity: 0, y: 10})
		gsap.set(art0txt1, {opacity: 0, y: -10})

		let tl = gsap.timeline({ paused: false });
			tl.to(art0rect, {opacity: 1, scale: 1, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(art0circle, {opacity: 1, scale: 1, duration: 1, ease: `power1.out` }, 0.3);
			tl.to(art0txt0, {opacity: 1, y: 0, duration: 0.5, ease: `power1.out` }, 0.9);
			tl.to(art0txt1, {opacity: 1, y: 0, duration: 0.5, ease: `power1.out` }, 1.0);
	}

	function leave0(){
		let art0rect = document.querySelectorAll(`#art0rect`);
		let art0circle = document.querySelectorAll(`#art0circle`);
		let art0txt0 = document.querySelectorAll(`#art0txt0`);
		let art0txt1 = document.querySelectorAll(`#art0txt1`);

		gsap.killTweensOf([art0rect,art0circle,art0txt0,art0txt1]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art0rect, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art0circle, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art0txt0, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art0txt1, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
	}


	function enter1(){
		let art1sun = document.querySelectorAll(`#art1sun`);
		let art1floor = document.querySelectorAll(`#art1floor`);
		let art1factory = document.querySelectorAll(`#art1factory`);
		let art1pipe1 = document.querySelectorAll(`#art1pipe1`);
		let art1pipe2 = document.querySelectorAll(`#art1pipe2`);

		gsap.killTweensOf([art1sun,art1floor,art1factory,art1pipe1,art1pipe2]);
		gsap.set(art1sun, {opacity: 0, y:40, x:10})
		gsap.set(art1floor, {opacity: 0})
		gsap.set(art1factory, {opacity: 0})
		gsap.set(art1pipe1, {opacity: 0, y: 10})
		gsap.set(art1pipe2, {opacity: 0, y: 10})

		let tl = gsap.timeline({ paused: false });
			tl.to(art1sun, {opacity: 1, y: 0, x: 0, duration: 2, ease: `power1.out` }, 0.0);
			tl.to(art1floor, {opacity: 1, duration: 1, ease: `power1.out` }, 0.1);
			tl.to(art1factory, {opacity: 1, duration: 1, ease: `power1.out` }, 0.3);
			tl.to(art1pipe1, {opacity: 1, y: 0, duration: 1, ease: `power1.out` }, 0.8);
			tl.to(art1pipe2, {opacity: 1, y: 0, duration: 1, ease: `power1.out` }, 1.0);
	}

	function leave1(){
		let art1sun = document.querySelectorAll(`#art1sun`);
		let art1floor = document.querySelectorAll(`#art1floor`);
		let art1factory = document.querySelectorAll(`#art1factory`);
		let art1pipe1 = document.querySelectorAll(`#art1pipe1`);
		let art1pipe2 = document.querySelectorAll(`#art1pipe2`);

		gsap.killTweensOf([art1sun,art1floor,art1factory,art1pipe1,art1pipe2]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art1sun, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art1floor, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art1factory, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art1pipe1, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art1pipe2, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
	}


	function enter2(){
		let art2bar = document.querySelectorAll(`.art2bar`);
		let art2txt0 = document.querySelectorAll(`.art2txt0`);
		let art2txt1 = document.querySelectorAll(`.art2txt1`);
		gsap.killTweensOf([art2bar,art2txt0,art2txt1]);
		gsap.set(art2bar, {opacity: 0, scaleX: 0})
		gsap.set(art2txt0, {opacity: 0})
		gsap.set(art2txt1, {opacity: 0})

		let tl = gsap.timeline({ paused: false });
			tl.to(art2bar, {opacity: 1, scaleX: 1, duration: 1, ease: `power1.out`, stagger: 0.2 }, 0.0);
			tl.to(art2txt0, {opacity: 1, duration: 1, ease: `power1.out`, stagger: 0.2 }, 0.8);
			tl.to(art2txt1, {opacity: 1, duration: 1, ease: `power1.out`, stagger: 0.2 }, 1.0);
	}

	function leave2(){
		let art2bar = document.querySelectorAll(`.art2bar`);
		let art2txt0 = document.querySelectorAll(`.art2txt0`);
		let art2txt1 = document.querySelectorAll(`.art2txt1`);
		gsap.killTweensOf([art2bar,art2txt0,art2txt1]);
		// gsap.set(art2bar, {opacity: 0, scaleX: 0})
		// gsap.set(art2txt0, {opacity: 0})
		// gsap.set(art2txt1, {opacity: 0})

		let tl = gsap.timeline({ paused: false });
			tl.to(art2bar, {opacity: 0, duration: 0.2, ease: `power1.out`, stagger: 0.0 }, 0.0);
			tl.to(art2txt0, {opacity: 0, duration: 0.2, ease: `power1.out`, stagger: 0.0 }, 0.0);
			tl.to(art2txt1, {opacity: 0, duration: 0.2, ease: `power1.out`, stagger: 0.0 }, 0.0);
	}


	function enter3(){
		let art3sun = document.querySelectorAll(`#art3sun`);
		let art3hospital = document.querySelectorAll(`#art3hospital`);
		let art3tower = document.querySelectorAll(`#art3tower`);
		let art3floor = document.querySelectorAll(`#art3floor`);
		let art3man = document.querySelectorAll(`#art3man`);
		let art3old = document.querySelectorAll(`#art3old`);

		gsap.killTweensOf([art3sun,art3hospital,art3tower,art3floor,art3man,art3old]);
		gsap.set(art3sun, {opacity: 0, y:40, x:10})
		gsap.set(art3hospital, {opacity: 0})
		gsap.set(art3tower, {opacity: 0})
		gsap.set(art3floor, {opacity: 0})
		gsap.set(art3old, {opacity: 0, x: 5})
		gsap.set(art3man, {opacity: 0, x: 5})

		let tl = gsap.timeline({ paused: false });
			tl.to(art3sun, {opacity: 1, y: 0, x: 0, duration: 2, ease: `power1.out` }, 0.0);
			tl.to(art3floor, {opacity: 1, duration: 1, ease: `power1.out` }, 0.0);
			tl.to(art3hospital, {opacity: 1, duration: 1, ease: `power1.out` }, 0.3);
			tl.to(art3tower, {opacity: 1, duration: 1, ease: `power1.out` }, 0.5);
			tl.to(art3old, {opacity: 1, x: 0, duration: 1, ease: `power1.out` }, 1.0);
			tl.to(art3man, {opacity: 1, x: 0, duration: 1, ease: `power1.out` }, 1.2);

	}

	function leave3(){
		let art3sun = document.querySelectorAll(`#art3sun`);
		let art3hospital = document.querySelectorAll(`#art3hospital`);
		let art3tower = document.querySelectorAll(`#art3tower`);
		let art3floor = document.querySelectorAll(`#art3floor`);
		let art3man = document.querySelectorAll(`#art3man`);
		let art3old = document.querySelectorAll(`#art3old`);

		gsap.killTweensOf([art3sun,art3hospital,art3tower,art3floor,art3man,art3old]);

		let tl = gsap.timeline({ paused: false });
			tl.to(art3sun, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art3floor, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art3hospital, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art3tower, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art3man, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
			tl.to(art3old, {opacity: 0, duration: 0.2, ease: `power1.out` }, 0.0);
	}


	return (
		<section className={s.article} >

			<div 
				className={s.headerbg} 
				style={{backgroundImage: `url(${withpath(`/img/bg2.jpg`)})` }}
			>
				<div className={s.svgbg}
					style={{backgroundImage: `url(${withpath(`/img/cover.svg`)})` }}
				/>
				<div className={s.headerbox}>
					<div className={s.hicon}>
						<Icon />
					</div>
					<div className={s.h0} dangerouslySetInnerHTML={{__html: data.h0 }} />
					<div className={s.desc} dangerouslySetInnerHTML={{__html: data.desc }} />
				</div>
			</div>

			<div className={s.box} data-id={0}>
				
				<div className={s.tbox} data-id={0}>
					<div className={s.tboxl} data-id={0}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[0] }} data-id={0} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[1] }} data-id={1} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[2] }} data-id={2} />
					</div>
					<div className={s.tboxr} data-id={0}>
					<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
						<div className={s.quotesbox}>
							<div className={s.quotes}>
								
								<Quotes />
								
							</div>
							<div className={s.quotestxt} dangerouslySetInnerHTML={{__html: data.quotes[0].txt }} />
							<div className={s.quoteshr} />
							<div className={s.quotesname} dangerouslySetInnerHTML={{__html: data.quotes[0].name }} />
						</div>
						</ScrollAnimation>
					</div>
				</div>

				<div className={s.tbox} data-id={1}>
					<div className={s.tboxl} data-id={1}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[3] }} data-id={3} />
						<Waypoint 
							onEnter={(p) => {enter0()}} 
							onLeave={(p) => {leave0()}} 
							bottomOffset={waypercent} 
							scrollableAncestor={`window`} 
							fireOnRapidScroll={false}
						>
						<div className={s.art0}>
							<Art0 />
						</div>
						</Waypoint>
					</div>
					<div className={s.tboxr} data-id={1}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[4] }} data-id={0} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[5] }} data-id={5} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[6] }} data-id={6} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[7] }} data-id={7} />
					</div>
				</div>

			</div>

			<div className={s.box} data-id={1}>
				<div className={s.tbox} data-id={2}>
					<div className={s.tboxl} data-id={2}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[8] }} data-id={8} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[9] }} data-id={9} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[10] }} data-id={10} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[11] }} data-id={11} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[12] }} data-id={12} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[13] }} data-id={13} />
					</div>
					<div className={s.tboxr} data-id={2}>
						<div className={s.quotesbox}>
							<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<div>
							<div className={s.quotes}>
								
								<Quotes />
								
							</div>
							<div className={s.quotestxt} dangerouslySetInnerHTML={{__html: data.quotes[1].txt }} />
							</div>
							</ScrollAnimation>


							<Waypoint 
								onEnter={(p) => {enter1()}} 
								onLeave={(p) => {leave1()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art1}>
									<Art1 />
								</div>
							</Waypoint>
						</div>
					</div>
				</div>
			</div>


			<div className={s.box} data-id={2}>
				
				<div className={s.tbox} data-id={3}>
					<div className={s.tboxl} data-id={3}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[14] }} data-id={14} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[15] }} data-id={15} />
					</div>
					<div className={s.tboxr} data-id={3}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[17] }} data-id={17} />
							<Waypoint 
								onEnter={(p) => {enter2()}} 
								onLeave={(p) => {leave2()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art2}>
									<Art2 />
								</div>
							</Waypoint>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[16] }} data-id={16} />
					</div>
				</div>
			</div>

			<div className={s.box} data-id={3}>
				<div className={s.tbox} data-id={4}>
					<div className={s.tboxl} data-id={4}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[18] }} data-id={18} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[19] }} data-id={19} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[20] }} data-id={20} />
							<Waypoint 
								onEnter={(p) => {enter3()}} 
								onLeave={(p) => {leave3()}} 
								bottomOffset={waypercent} 
								scrollableAncestor={`window`} 
								fireOnRapidScroll={false}
							>
								<div className={s.art3}>
									<Art3 />
								</div>
							</Waypoint>
					</div>
					<div className={s.tboxr} data-id={4}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[21] }} data-id={21} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[22] }} data-id={22} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[23] }} data-id={23} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[24] }} data-id={24} />
					</div>
				</div>
			</div>

			<div className={s.box} data-id={4}>
				<div className={s.tbox} data-id={5}>
					<div className={s.tboxl} data-id={5}>
						<div className={s.htxt} dangerouslySetInnerHTML={{__html: data.txt[25] }} data-id={25} />
						<div className={s.hr} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[26] }} data-id={26} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[27] }} data-id={27} />
					</div>
					<div className={s.tboxr} data-id={5}>
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[28] }} data-id={28} />
						<div className={s.txt} dangerouslySetInnerHTML={{__html: data.txt[29] }} data-id={29} />
					</div>
				</div>

{/*
				<div className={s.tbox} data-id={6}>
					<div className={s.sources} dangerouslySetInnerHTML={{__html: `Sources:`}} />
					<a href={data.sources[0]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `1. ` + data.sources[0] }} />
					<a href={data.sources[1]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `2. ` + data.sources[1] }} />
					<a href={data.sources[2]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `3. ` + data.sources[2] }} />
					<a href={data.sources[3]} target={`_blank`} className={s.source} dangerouslySetInnerHTML={{__html: `4. ` + data.sources[3] }} />
				</div>
*/}


			</div>

		</section>
)}

export default Main
